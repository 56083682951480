import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image"

export default function TeamCards() {
    const data = useStaticQuery (
        graphql`
            query {
                allMarkdownRemark (sort: { fields: [frontmatter___sort], order: ASC }, filter: { frontmatter: {type: {eq: "team"}}}) {
                    edges {
                        node {
                            id
                            frontmatter {
                                sort
                                name
                                mail
                                job
                                image {
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                }
                            }
                        }
                    }
                },
            }
        `
    )
    return (
        <div className="cards">
            {data.allMarkdownRemark.edges.map(({node}) => (
                <div key={node.id} id={node.id} className="card">
                    <GatsbyImage alt={ "Foto von " + node.frontmatter.name } image={ getImage(node.frontmatter.image.childImageSharp) } />
                    <h2>{node.frontmatter.name}</h2>
                    <a href={"mailto:" + node.frontmatter.mail}>{node.frontmatter.mail}</a>
                    <p>{node.frontmatter.job}</p>
                </div>
            ))}
            <div className="card">
                <p>Wir bieten Ihnen eine persönliche und engagierte Betreuung in sämtlichen treuhänderischen Belangen zu fairen Preisen. Unser geographisches Tätigkeitsgebiet erstreckt sich hauptsächlich auf den Kanton Bern sowie die angrenzende Region.</p>
            </div>
        </div>
    )
}