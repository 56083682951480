import * as React from "react"
import ArrowDown from "../../images/arrow.svg"
import scrollTo from "gatsby-plugin-smoothscroll"
import LogoW from "../../images/logo_w.svg"
import PropTypes from "prop-types";

const Home = ({ siteTitle }) => (
    <section className={ siteTitle } id={ siteTitle }>
        <div className="logo">
            <LogoW />
        </div>
        <h1>Wyssmann Treuhand GmbH</h1>

        <ArrowDown className="arrow" onClick={() => scrollTo('#Dienstleistungen')} />
    </section>
)

Home.propTypes = {
    siteTitle: PropTypes.string,
}

Home.defaultProps = {
    siteTitle: `Home`,
}

export default Home