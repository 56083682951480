import * as React from "react"
import Seo from "../components/common/seo"
import Header from "../components/sections/header"
import Home from "../components/sections/home"
import Dienstleistungen from "../components/sections/dienstleistungen"
import UeberUns from "../components/sections/ueber-uns"
import Kontakt from "../components/sections/kontakt"
import Footer from "../components/sections/footer"

import "../styles/main.scss"

const IndexPage = () => (
  <>
    <Seo title="Wyssmann Treuhand" />
    <Header />
    <Home />
    <Dienstleistungen />
    <UeberUns />
    <Kontakt />
    <Footer />
  </>
)

export default IndexPage
