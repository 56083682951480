import * as React from "react"
import PropTypes from "prop-types"
import GoogleMap from "../elements/google-map"

const Kontakt = ({ siteTitle }) => (
    <section className={siteTitle}>
        <div id={siteTitle} className={"anchor"}> </div>
        <div className={"content"}>
            <h1>Kontakt</h1>
            <div className={"cards"}>
                <div className={"card"}>
                    <h2>Wyssmann Treuhand GmbH</h2>
                    <p className={"address"}>
                        Süristrasse 50 / Bramberg
                        <br/>
                        3176 Neuenegg
                    </p>
                    <div className={"contact"}>
                        <div className={"tel"}>
                            <p>Tel.:</p>
                            <a href={"tel:+41317475919"}>031 747 59 19</a>
                        </div>
                        <div className={"email"}>
                            <p>E-Mail:</p>
                            <a href={"mailto:info@wyssmann-treuhand.ch"}>info@wyssmann-treuhand.ch</a>
                        </div>
                    </div>
                </div>
                <div className={"card"}>
                    <GoogleMap />
                </div>
            </div>
        </div>
    </section>
)

Kontakt.propTypes = {
    siteTitle: PropTypes.string,
}

Kontakt.defaultProps = {
    siteTitle: `Kontakt`,
}

export default Kontakt
