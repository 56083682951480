import React from "react"

const googleMap = () => (
    <div className="mapouter">
        <div className="gmap_canvas">
            <iframe width="600" height="500" id="gmap_canvas"
                    src="https://maps.google.com/maps?q=s%C3%BCristrasse%2050,%20neuenegg&t=&z=15&ie=UTF8&iwloc=&output=embed"
                    frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" title="google-map-frame" />
        </div>
    </div>
)

export default googleMap
