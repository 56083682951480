import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export default function DlCards() {
    const data = useStaticQuery (
        graphql`
            query {
                allMarkdownRemark (sort: { fields: [frontmatter___sort], order: ASC }, filter: { frontmatter: {type: {eq: "dienstleistung"}}}) {
                    edges {
                        node {
                            id
                            html
                            frontmatter {
                                title
                                sort
                            }
                        }
                    }
                },
            }
        `
    )
    return (
        <div className="cards">
            {data.allMarkdownRemark.edges.map(({node}) => (
                <div key={node.id} id={node.id} className="card">
                    <h2>{node.frontmatter.title}</h2>
                    <div dangerouslySetInnerHTML={{ __html: node.html }} />
                </div>
            ))}
        </div>
    )
}