import * as React from "react"
import PropTypes from "prop-types"
import DlCards from "../elements/dlCards"

const Dienstleistungen = ({ siteTitle }) => (
    <section className={ siteTitle }>
        <div id={siteTitle} className={"anchor"}> </div>
        <div className={"content"}>
            <h1>Unsere Treuhand-Dienstleistungen</h1>
            <DlCards />
        </div>
    </section>
)

Dienstleistungen.propTypes = {
    siteTitle: PropTypes.string,
}

Dienstleistungen.defaultProps = {
    siteTitle: `Dienstleistungen`,
}

export default Dienstleistungen
