import * as React from "react"
import PropTypes from "prop-types"
import TeamCards from "../elements/teamCards"

const UeberUns = ({ siteTitle }) => (
    <section className={ siteTitle }>
        <div id={siteTitle} className={"anchor"}> </div>
        <div className={"content"}>
            <h1>Über uns</h1>
            <TeamCards />
        </div>
    </section>
)

UeberUns.propTypes = {
    siteTitle: PropTypes.string,
}

UeberUns.defaultProps = {
    siteTitle: `UeberUns`,
}

export default UeberUns
